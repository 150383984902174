import React, { useEffect, useState } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { Insights } from '../../types/insights';

function getRandomColor() {
	var letters = '0123456789ABCDEF'.split('');
	var color = '#';
	for (var i = 0; i < 6; i++) {
		color += letters[Math.floor(Math.random() * 16)];
	}
	return color;
}

const MonthlyStack = ({ insights }: { insights: Insights[] }) => {
	const [tooltip, setTooltip] = useState('');
	const [keys, setKeys] = useState([] as string[]);
	const [colours, setColours] = useState([] as string[]);
	useEffect(() => {
		const colours = [
			'#ea5545',
			'#f46a9b',
			'#ef9b20',
			'#edbf33',
			'#ede15b',
			'#bdcf32',
			'#87bc45',
			'#27aeef',
			'#b33dc6',
		];
		const newKeys = insights.reduce((keySet, curr) => {
			Object.keys(curr)
				.filter((key) => key !== 'date')
				.forEach((key) => keySet.add(key));
			return keySet;
		}, new Set<string>());
		setKeys(Array.from(newKeys));
		for (let i = 0; i < newKeys.size - 10; i++) {
			colours.push(getRandomColor());
		}
		setColours(colours);
	}, [insights]);

	return (
		<ResponsiveContainer width="95%" aspect={1}>
			<BarChart
				width={500}
				height={300}
				data={insights}
				margin={{
					top: 20,
					right: 30,
					left: 20,
					bottom: 5,
				}}
				stackOffset={'expand'}
			>
				<CartesianGrid strokeDasharray="3 3" />
				<XAxis dataKey="date" />
				<YAxis />
				<Legend />
				<Tooltip
					content={({ active, payload }) => (
						<CustomTooltip active={active} tooltip={tooltip} payload={payload} />
					)}
				/>
				{keys.map((key, indx) => (
					<Bar
						dataKey={key}
						stackId="a"
						fill={colours[indx]}
						onMouseOver={() => {
							setTooltip(key);
						}}
					/>
				))}
			</BarChart>
		</ResponsiveContainer>
	);
};

const CustomTooltip = ({ active, payload, tooltip }: { active?: any; payload?: any; tooltip: any }) => {
	if (!active || !tooltip) return null;
	for (const bar of payload)
		if (bar.dataKey === tooltip)
			return (
				<div>
					{bar.name}
					<br />
					{bar.value.toFixed(2)}
				</div>
			);
	return null;
};

export default MonthlyStack;
