import { createContext } from 'react';
import { Category, CategoryState, CATEGORY_ACTIONS, SubCategory } from './CategoryState';

type actionType =
	| {
			type: CATEGORY_ACTIONS.ADD_CATEGORY;
			data: {
				id: number;
				name: string;
			};
	  }
	| {
			type: CATEGORY_ACTIONS.UPDATE_CATEGORY;
			data: {
				id: number;
				name: string;
			};
	  }
	| {
			type: CATEGORY_ACTIONS.DELETE_CATEGORY;
			data: {
				id: number;
			};
	  }
	| {
			type: CATEGORY_ACTIONS.ADD_SUB_CATEGORY;
			data: SubCategory;
	  }
	| {
			type: CATEGORY_ACTIONS.DELETE_SUB_CATEGORY;
			data: {
				id: number;
			};
	  }
	| {
			type: CATEGORY_ACTIONS.GET_CATEGORIES;
			data: Category[];
	  }
	| {
			type: CATEGORY_ACTIONS.GET_SUB_CATEGORIES;
			data: SubCategory[];
	  };

export default function handleState(state: CategoryState, action: actionType): CategoryState {
	switch (action.type) {
		case CATEGORY_ACTIONS.GET_CATEGORIES: {
			return { ...state, categories: action.data };
		}
		case CATEGORY_ACTIONS.GET_SUB_CATEGORIES: {
			return { ...state, subCategories: action.data };
		}
		case CATEGORY_ACTIONS.ADD_CATEGORY: {
			return { ...state, categories: [...state.categories, { ...action.data }] };
		}
		case CATEGORY_ACTIONS.ADD_SUB_CATEGORY: {
			return { ...state, subCategories: [...state.subCategories, { ...action.data }] };
		}
		case CATEGORY_ACTIONS.DELETE_CATEGORY: {
			return {
				...state,
				categories: [...state.categories.filter((category) => category.id !== action.data.id)],
			};
		}
		case CATEGORY_ACTIONS.DELETE_SUB_CATEGORY: {
			return {
				...state,
				subCategories: [...state.subCategories.filter((category) => category.id !== action.data.id)],
			};
		}
	}
	return state;
}
