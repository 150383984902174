import axios from 'axios';
import { createContext, useContext, useEffect, useReducer } from 'react';
import { AddTransaction, GetTransactionTypes, TransactionReducer, Transactions } from '../types/transactions';
import { GlobalContext } from './GlobalState';
import TransactionReduce from './TransactionReducer';

export type TransactionStateParams = {
	addTransactionLoading: boolean;
	transactions: Transactions[];
	transactionTypes: GetTransactionTypes;
	error: string;
};

export type TransactionState = {
	deleteTransaction: (id: number) => void;
	addTransaction: (transaction: AddTransaction) => void;
} & TransactionStateParams;

type PostTransaction = {
	message: string;
	id: string;
};

const initialState: TransactionState = {
	addTransactionLoading: false,
	transactions: [],
	transactionTypes: { transaction_subtypes: [], transaction_types: [] },
	error: '',
	deleteTransaction: () => {},
	addTransaction: () => {},
};
// Context
export const TransactionContext = createContext(initialState);

export const TransactionProvider = ({ children }: any) => {
	useEffect(() => {
		getTransactions();
		getTransactionTypes();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	const [state, dispatch] = useReducer(TransactionReduce, initialState);
	const { showNotification } = useContext(GlobalContext);
	// Actions
	async function getTransactions() {
		const splitFromDate = new Date().toISOString().split('T')[0].split('-');
		try {
			const res = await axios.get<Transactions>('/transactions', {
				params: { date: splitFromDate[0] + '-' + splitFromDate[1] },
			});
			dispatch({
				type: TransactionReducer.GET_TRANSACTIONS,
				payload: res.data,
			});
		} catch (err) {
			dispatch({
				type: TransactionReducer.TRANSACTION_ERROR,
				payload: err,
			});
		}
	}

	async function getTransactionTypes() {
		try {
			const res = await axios.get<GetTransactionTypes>('/transactions/types');
			dispatch({
				type: TransactionReducer.GET_TRANSACTION_TYPES,
				payload: res.data,
			});
		} catch (err) {
			dispatch({
				type: TransactionReducer.GET_TRANSACTION_TYPES_ERROR,
				payload: err,
			});
		}
	}

	async function deleteTransaction(id: number) {
		await axios.delete('/transactions/' + id);
		dispatch({
			type: TransactionReducer.DELETE_TRANSACTION,
			payload: id,
		});
	}
	async function addTransaction(transaction: AddTransaction) {
		dispatch({
			type: TransactionReducer.START_ADD_TRANSACTION,
			payload: transaction,
		});
		try {
			const formData = new FormData();
			console.log(transaction);
			Object.entries(transaction).forEach((entry) => formData.append(entry[0], entry[1].toString()));
			const res = await axios.post<PostTransaction>('/transactions', formData, {});
			showNotification({
				title: 'Success',
				message: 'Transaction added successfully',
				type: 'success',
			});
			dispatch({
				type: TransactionReducer.ADD_TRANSACTION,
				payload: { ...transaction, id: res.data.id },
			});
		} catch (err) {
			dispatch({
				type: TransactionReducer.ADD_TRANSACTION_ERROR,
				payload: err,
			});
		}
	}

	return (
		<TransactionContext.Provider
			value={{
				transactionTypes: state.transactionTypes,
				transactions: state.transactions,
				error: state.error,
				addTransactionLoading: state.addTransactionLoading,
				deleteTransaction,
				addTransaction,
			}}
		>
			{children}
		</TransactionContext.Provider>
	);
};

type filter = {
	value: 'sum' | 'total percentage of month';
	subCategories: number[];
};
