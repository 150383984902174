import React, { useContext } from 'react';
import { TransactionContext } from '../context/TransactionState';
import Transaction from './Transaction';

const TransactionList = () => {
	const { transactions } = useContext(TransactionContext);
	return (
		<>
			<h3>History</h3>
			<ul id="list" className="list">
				{transactions
					.slice(-10)
					.reverse()
					.map((transaction) => (
						<Transaction key={transaction.id} transaction={transaction} />
					))}
			</ul>
		</>
	);
};

export default TransactionList;
