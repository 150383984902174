import React, { useContext } from 'react';
import { CategoryContext, ExpenseType } from '../../context/category/CategoryState';
import { SingleValueGraphType } from '../../context/Insights/InsightsState';
import { TransactionContext } from '../../context/TransactionState';

function addZeroes(num: number) {
	const toStr = String(num);
	const dec = toStr.split('.')[1];
	const len = dec && dec.length > 2 ? dec.length : 2;
	return Number(num).toFixed(len);
}

const SingleValueGraph = ({ graph, name }: { graph: SingleValueGraphType; name: string }) => {
	const { transactions } = useContext(TransactionContext);
	const { subCategories } = useContext(CategoryContext);

	let value = '0';

	if (graph.value === 'percentage') {
		let current = 0;
		let total = 0;
		transactions.forEach((transaction) => {
			const subCategory = subCategories.find((category) => category.id === transaction.category);
			if (!subCategory || subCategory.type === ExpenseType.INCOME) return;
			total += transaction.cost;
			console.log(transaction.category);
			if (graph.categories.includes(transaction.category || -1)) {
				current += transaction.cost;
			}
		});
		console.log({ current, total, categories: graph.categories });
		let percentage = current / total;
		percentage = Math.floor((10000.0 * current) / total) / 100;
		value = addZeroes(percentage);
	}
	if (graph.value === 'sum') {
		let current = 0;
		transactions.forEach((transaction) => {
			const subCategory = subCategories.find((category) => category.id === transaction.category);
			if (!subCategory || subCategory.type === ExpenseType.INCOME) return;
			if (graph.categories.includes(transaction.category || -1)) {
				current += transaction.cost;
			}
		});
		value = String(current);
	}

	return (
		<div>
			{name} {value}
			{graph.value === 'percentage' ? '%' : ''}
		</div>
	);
};

export default SingleValueGraph;
