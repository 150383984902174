import { GraphObject, InsightsState } from './InsightsState';

export enum InsightsActions {
	GET_ALL,
}

type WrappedActions = {
	type: InsightsActions;
	data: GraphObject[];
};

export default function handleState(state: InsightsState, action: WrappedActions): InsightsState {
	switch (action.type) {
		case InsightsActions.GET_ALL: {
			return { ...state, graphs: action.data };
		}
	}
	return state;
}
