import React from 'react';
import AddTransaction from '../components/AddTransaction';
import Balance from '../components/Balance';
import IncomeExpenses from '../components/IncomeExpenses';
import TransactionList from '../components/TransactionList';

const Main = () => {
	return (
		<div>
			<div className="container">
				<Balance />
				<IncomeExpenses />
				<AddTransaction />
				<TransactionList />
			</div>
		</div>
	);
};

export default Main;
