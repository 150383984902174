import React, { useContext } from 'react';
import { Category, CategoryContext, ExpenseType, SubCategory } from '../context/category/CategoryState';
import { TransactionContext } from '../context/TransactionState';
import { Transactions } from '../types/transactions';

function getCategory(transaction: Transactions, categories: Category[], subcategories: SubCategory[]) {
	const subCategory = subcategories.find((category) => category.id === transaction.category);
	if (!subCategory) {
		return;
	}
	const category = categories.find((category) => category.id === subCategory.categoryId);
	if (!category) {
		return;
	}
	return { subCategory, category };
}

const Transaction = ({ transaction }: { transaction: Transactions }) => {
	const { deleteTransaction, transactionTypes } = useContext(TransactionContext);
	const { categories, subCategories } = useContext(CategoryContext);

	const category = getCategory(transaction, categories, subCategories);

	let sign = '';
	let mainCategory = '';
	let subCategory = '';

	if (category) {
		sign = category.subCategory.type === ExpenseType.EXPENSE ? '-' : '+';
		mainCategory = category.category.name;
		subCategory = category.subCategory.name;
	} else {
		const type = transactionTypes.transaction_types.find((type) => type.id === transaction.type?.toString());
		const subtype = transactionTypes.transaction_subtypes.find(
			(subtype) => Number(subtype.id) === transaction.subtype,
		);

		if (!type) {
			return (
				<li className={'plus'}>
					{transaction.description} <span>+${Math.abs(transaction.cost)}</span>
					<button className="delete-btn" onClick={() => deleteTransaction(transaction.id)}>
						x
					</button>
				</li>
			);
		}
		sign = type.transaction_type === 'expense' ? '-' : '+';
		mainCategory = type.name;
		subCategory = subtype?.name || '';
	}

	return (
		<li className={sign === '-' ? 'minus' : 'plus'}>
			{mainCategory} {subCategory !== '' ? '(' + subCategory + ')' : ''}
			<span>
				{sign}${Math.abs(transaction.cost)}
			</span>
			<button className="delete-btn" onClick={() => deleteTransaction(transaction.id)}>
				x
			</button>
		</li>
	);
};

export default Transaction;
