import React, { useContext, useEffect, useState } from 'react';
import { CategoryContext, SubCategory } from '../context/category/CategoryState';
import { TransactionContext } from '../context/TransactionState';

const IncomeExpenses = () => {
	const { subCategories } = useContext(CategoryContext);
	const { transactions } = useContext(TransactionContext);
	const [income, setIncome] = useState(0);
	const [expense, setExpense] = useState(0);

	useEffect(() => {
		const sortedTransactions = transactions.reduce(
			(prev, curr) => {
				const category: SubCategory | undefined = subCategories.find(
					(subCategory) => subCategory.id === curr.category,
				);
				if (!category) {
					prev.income += curr.cost;
					return prev;
				}
				category.type === 'expense' ? (prev.expense += curr.cost) : (prev.income += curr.cost);
				return prev;
			},
			{ income: 0, expense: 0 },
		);
		setIncome(sortedTransactions.income);
		setExpense(sortedTransactions.expense);
	}, [transactions, subCategories]);

	return (
		<div className="inc-exp-container">
			<div>
				<h4>Income</h4>
				<p id="money-plus" className="money plus">
					+${income.toFixed(2)}
				</p>
			</div>
			<div>
				<h4>Expense</h4>
				<p id="money-minus" className="money minus">
					-${expense.toFixed(2)}
				</p>
			</div>
		</div>
	);
};

export default IncomeExpenses;
