import { FcSettings } from 'react-icons/fc';
import { Pages } from '../pages/PageSelector';

const Header = ({ activePage, onPageChange }: { activePage: Pages; onPageChange: (page: Pages) => void }) => {
	const pages = Object.entries(Pages).filter((page) => page[1] !== Pages.Settings);
	return (
		<div className="header">
			{pages.map((page) => (
				<button
					key={page[1]}
					className="btn headerBtn"
					disabled={page[1] === activePage}
					onClick={() => onPageChange(page[1])}
				>
					{page[0]}
				</button>
			))}
			<button className="btn headerBtn settingsButton" onClick={() => onPageChange(Pages.Settings)}>
				<FcSettings alignmentBaseline="middle" width="2em" />
			</button>
		</div>
	);
};

export default Header;
