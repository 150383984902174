import axios from 'axios';
import { createContext, useReducer } from 'react';
import { NOTIFICATION_TYPE } from 'react-notifications-component';
import { GlobalReducerTypes } from '../types/globalstate';
import { Insights } from '../types/insights';
import { TransactionReducer } from '../types/transactions';
import AppReducer from './AppReducer';

export type Notification = {
	title: string;
	message: string;
	type: NOTIFICATION_TYPE;
};

export type GlobalStateParams = {
	notifications: Notification[];
	loggedIn: boolean;
	error?: string;
	loading: boolean;
	insights: Insights[];
};

export type GlobalState = {
	login: (email: string, password: string) => void;
	getInsights: (fromDate: Date, toDate: Date) => void;
	showNotification: (notification: Notification) => void;
	popNotification: () => void;
} & GlobalStateParams;

const initialState: GlobalState = {
	notifications: [],
	loggedIn: document.cookie.indexOf('token=') !== -1,
	insights: [],
	login: () => {},
	loading: true,
	getInsights: () => {},
	showNotification: () => {},
	popNotification: () => {},
};

// Context
export const GlobalContext = createContext(initialState);

//Provider

export const GlobalProvider = ({ children }: any) => {
	const [state, dispatch] = useReducer(AppReducer, initialState);

	async function showNotification(notification: Notification) {
		dispatch({
			type: GlobalReducerTypes.SET_NOTIFICATION,
			payload: notification,
		});
	}
	async function popNotification() {
		dispatch({
			type: GlobalReducerTypes.POP_NOTIFICATION,
			payload: {},
		});
	}

	async function login(username: string, password: string) {
		const formData = new FormData();
		formData.append('email', username);
		formData.append('password', password);
		try {
			await axios.post('/auth/login', formData);
			if (document.cookie.indexOf('token=') !== -1) {
				dispatch({
					type: TransactionReducer.LOGIN,
					payload: true,
				});
			} else {
				dispatch({
					type: TransactionReducer.LOGIN_ERROR,
					payload: 'Failed to get token',
				});
			}
		} catch (err: any) {
			dispatch({
				type: TransactionReducer.LOGIN_ERROR,
				payload: err.response.data,
			});
		}
	}

	async function getInsights(fromDate: Date, toDate: Date) {
		const splitFromDate = fromDate.toISOString().split('T')[0].split('-');
		const splitToDate = toDate.toISOString().split('T')[0].split('-');
		try {
			const res = await axios.get<Insights[]>('/insights/monthly', {
				params: {
					fromDate: splitFromDate[0] + '-' + splitFromDate[1],
					toDate: splitToDate[0] + '-' + splitToDate[1],
				},
			});
			dispatch({
				type: TransactionReducer.GET_INSIGHTS,
				payload: res.data,
			});
		} catch (err: any) {
			dispatch({
				type: TransactionReducer.GET_INSIGHTS_ERROR,
				payload: err.response.data,
			});
		}
	}

	return (
		<GlobalContext.Provider
			value={{
				notifications: state.notifications,
				error: state.error,
				loading: state.loading,
				loggedIn: state.loggedIn,
				insights: state.insights,
				getInsights,
				login,
				showNotification,
				popNotification,
			}}
		>
			{children}
		</GlobalContext.Provider>
	);
};
