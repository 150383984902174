import { GetTransactionTypes, TransactionReducer, Transactions } from '../types/transactions';
import { TransactionStateParams } from './TransactionState';

export default function handleState(
	state: {
		addTransactionLoading: boolean;
		transactions: Transactions[];
		transactionTypes: GetTransactionTypes;
		error: string;
	},
	action: { type: TransactionReducer; payload: any },
): TransactionStateParams {
	switch (action.type) {
		case TransactionReducer.GET_TRANSACTIONS:
			return {
				...state,
				transactions: action.payload,
			};
		case TransactionReducer.TRANSACTION_ERROR:
			return {
				...state,
				error: action.payload,
			};
		case TransactionReducer.DELETE_TRANSACTION:
			return {
				...state,
				transactions: state.transactions.filter((transaction) => transaction.id !== action.payload),
			};
		case TransactionReducer.ADD_TRANSACTION:
			return {
				...state,
				addTransactionLoading: false,
				transactions: [...state.transactions, action.payload],
			};
		case TransactionReducer.START_ADD_TRANSACTION:
			return {
				...state,
				addTransactionLoading: true,
			};
		case TransactionReducer.ADD_TRANSACTION_ERROR:
			return {
				...state,
				addTransactionLoading: false,
				error: action.payload,
			};
		case TransactionReducer.GET_TRANSACTION_TYPES:
			return {
				...state,
				transactionTypes: action.payload,
			};
		case TransactionReducer.GET_TRANSACTION_TYPES_ERROR:
			return {
				...state,
				error: action.payload,
			};
		default:
			return state;
	}
}
