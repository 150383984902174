import axios from 'axios';
import { createContext, useEffect, useReducer } from 'react';
import { Category } from '../category/CategoryState';
import InsightsReducer, { InsightsActions } from './InsightsReducer';

export type SingleValueGraphType = {
	value: 'percentage' | 'sum';
	categories: Category['id'][];
};

export type ConstantGraphType = {
	message: string;
};

export type GraphType = SingleValueGraphType;

export type GraphObject = {
	id: number;
	name: string;
	graph: GraphType;
	add_date: Date;
} & ({ graph: SingleValueGraphType; type: 'single_value' } | { graph: ConstantGraphType; type: 'constant' });

export type InsightsState = {
	graphs: GraphObject[];
};

const initialState: InsightsState = {
	graphs: [] as GraphObject[],
};

export const InsightsContext = createContext(initialState);

export const InsightsProvider = ({ children }: any) => {
	const [state, dispatch] = useReducer(InsightsReducer, initialState);

	useEffect(() => {
		async function getAllGraphs() {
			const response = await axios.get<GraphObject[]>('/insights/all');

			dispatch({
				type: InsightsActions.GET_ALL,
				data: response.data.map((graph) => ({ ...graph, add_date: new Date(graph.add_date) })),
			});
		}
		getAllGraphs();
	}, []);

	return <InsightsContext.Provider value={{ graphs: state.graphs }}>{children}</InsightsContext.Provider>;
};

export default InsightsState;
