import React, { useContext, useEffect, useState } from 'react';
import 'react-notifications-component/dist/theme.css';
import 'animate.css';
// Alternate way to use classes without prefix like `animated fadeIn`
import 'animate.css/animate.compat.css';
import { ReactNotifications, Store } from 'react-notifications-component';
import { GlobalContext } from '../context/GlobalState';

const Notification = () => {
	const [classAction, setClassAction] = useState('animated fadeOut');
	const { notifications, popNotification } = useContext(GlobalContext);

	useEffect(() => {
		const notification = notifications.at(0);
		if (!notification) {
			setTimeout(() => {
				setClassAction('animated fadeOut');
			}, 3000);
			return;
		}
		popNotification();
		Store.addNotification({
			title: notification.title,
			message: notification.message,
			type: notification.type, // 'default', 'success', 'info', 'warning'
			container: 'top-left', // where to position the notifications
			dismiss: {
				duration: 3000,
			},
		});

		setClassAction('animated flipInX');
	}, [notifications, popNotification]);

	return (
		<div className={'staticPos ' + classAction}>
			<ReactNotifications />
		</div>
	);
};

export default Notification;
