import { useContext, useEffect, useState } from 'react';
import { CategoryContext, SubCategory } from '../context/category/CategoryState';
import { TransactionContext } from '../context/TransactionState';

const Balance = () => {
	const { transactions } = useContext(TransactionContext);
	const { subCategories } = useContext(CategoryContext);
	const [balance, setBalance] = useState('0');

	useEffect(() => {
		const total = transactions
			.reduce((acc, item) => {
				const category: SubCategory | undefined = subCategories.find(
					(subCategory) => subCategory.id === item.category,
				);
				if (!category) {
					return (acc += item.cost);
				}
				return (acc += category.type === 'income' ? item.cost : -item.cost);
			}, 0)
			.toFixed(2);
		setBalance(total);
	}, [subCategories, transactions]);

	return (
		<div>
			<h4>YOUR BALANCE</h4>
			<h1 id="balance">${balance}</h1>
		</div>
	);
};

export default Balance;
