import { GlobalReducerTypes } from '../types/globalstate';
import { Insights } from '../types/insights';
import { TransactionReducer } from '../types/transactions';
import { GlobalStateParams } from './GlobalState';
import { Notification } from './GlobalState';

export default function handleState(
	state: {
		error?: string;
		loading: boolean;
		loggedIn: boolean;
		insights: Insights[];
		notifications: Notification[];
	},
	action: { type: TransactionReducer | GlobalReducerTypes; payload: any },
): GlobalStateParams {
	switch (action.type) {
		case TransactionReducer.TRANSACTION_ERROR:
			return {
				...state,
				loading: false,
				error: action.payload,
			};
		case TransactionReducer.LOGIN:
			return {
				...state,
				loading: false,
				loggedIn: true,
			};
		case TransactionReducer.LOGIN_ERROR:
			return {
				...state,
				loading: false,
				loggedIn: false,
				error: action.payload,
			};
		case TransactionReducer.GET_INSIGHTS:
			return {
				...state,
				loading: false,
				insights: action.payload,
			};
		case TransactionReducer.GET_INSIGHTS_ERROR:
			return {
				...state,
				loading: false,
				error: action.payload,
			};
		case GlobalReducerTypes.SET_NOTIFICATION:
			return {
				...state,
				notifications: [...state.notifications, action.payload],
			};
		case GlobalReducerTypes.POP_NOTIFICATION:
			return {
				...state,
				notifications: state.notifications.slice(1),
			};

		default:
			return state;
	}
}
