const Dropdown = ({
	active,
	values,
	label,
	onValueChanged,
	className,
}: {
	className?: string;
	active: number;
	label: string;
	values: { id: string | number; name: string }[];
	onValueChanged: (type: string) => void;
}) => {
	const handleChange = (event: any) => {
		onValueChanged(event.target.value);
	};
	const activeValue = values.find((value) => Number(value.id) === active);

	if (!activeValue) {
		return <></>;
	}

	return (
		<label className={className}>
			{label}
			<select value={activeValue.name} onChange={handleChange} className="width100 select-shadow">
				{values.map((value, indx) => (
					<option id="list" className="list" key={indx}>
						{value.name}
					</option>
				))}
			</select>
		</label>
	);
};

export default Dropdown;
