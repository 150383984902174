export type AddTransaction = {
	type?: number;
	subtype?: number;
	category?: number;
	cost: number;
	description: string;
};

export type Transactions = {
	id: number;
} & AddTransaction;

export type TransactionType = {
	id: string;
	name: string;
	description: string;
	transaction_type: 'expense' | 'income';
};

export type TransactionSubtype = {
	id: string;
	name: string;
	description: string;
	parent_id: string;
};

export type GetTransactionTypes = {
	transaction_types: TransactionType[];
	transaction_subtypes: TransactionSubtype[];
};

export enum TransactionReducer {
	GET_TRANSACTIONS = 'GET_TRANSACTIONS',
	TRANSACTION_ERROR = 'TRANSACTION_ERROR',
	DELETE_TRANSACTION = 'DELETE_TRANSACTION',
	START_ADD_TRANSACTION = 'START_ADD_TRANSACTION',
	ADD_TRANSACTION = 'ADD_TRANSACTION',
	ADD_TRANSACTION_ERROR = 'ADD_TRANSACTION_ERROR',
	GET_TRANSACTION_TYPES = 'GET_TRANSACTION_TYPES',
	GET_TRANSACTION_TYPES_ERROR = 'GET_TRANSACTION_TYPES_ERROR',
	LOGIN = 'LOGIN',
	LOGIN_ERROR = 'LOGIN_ERROR',
	GET_INSIGHTS = 'GET_INSIGHTS',
	GET_INSIGHTS_ERROR = 'GET_INSIGHTS_ERROR',
}
