import './App.css';
import { GlobalProvider } from './context/GlobalState';
import PageSelector from './pages/PageSelector';

function App() {
	return (
		<GlobalProvider>
			<PageSelector />
		</GlobalProvider>
	);
}

export default App;
