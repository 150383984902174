import React, { useContext, useState, useEffect } from 'react';
import { InfinitySpin } from 'react-loader-spinner';
import { Category, CategoryContext, SubCategory } from '../context/category/CategoryState';
import { TransactionContext } from '../context/TransactionState';
import Dropdown from './Reusable/Dropdown';

const AddTransaction = () => {
	const { addTransactionLoading, addTransaction } = useContext(TransactionContext);
	const { categories, subCategories } = useContext(CategoryContext);

	const [description, setDescription] = useState('');
	const [cost, setCost] = useState('');
	const [category, setCategory] = useState(2);
	const [subcategory, setSubCategory] = useState(0);

	const [allowedCategories, setAllowedCategories] = useState([] as Category[]);
	const [allowedSubCategories, setAllowedSubCategories] = useState([] as SubCategory[]);

	useEffect(() => {
		const categoryMap = categories.reduce((acc, current) => {
			const matchingCategories = subCategories.filter((subcategory) => subcategory.categoryId === current.id);
			if (matchingCategories.length > 0) {
				return [...acc, { category: current, subcategories: matchingCategories }];
			}
			return acc;
		}, [] as { category: Category; subcategories: SubCategory[] }[]);

		if (categoryMap.length === 0) {
			return;
		}

		const newAllowedCategories = categoryMap.map((categoryGroup) => categoryGroup.category);
		setAllowedCategories(newAllowedCategories);

		setCategory(newAllowedCategories[0].id);

		const newAllowedSubcategories = categoryMap.find(
			(categoryGroup) => categoryGroup.category.id === newAllowedCategories[0].id,
		)!.subcategories;

		setSubCategory(newAllowedSubcategories[0].id);
		setAllowedSubCategories(newAllowedSubcategories);
	}, [categories, subCategories]);

	if (allowedCategories.length === 0) {
		return <></>;
	}

	const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		if (Number.isNaN(cost)) {
			return;
		}
		if (Number(cost) === 0) {
			return;
		}
		addTransaction({
			cost: Number(cost),
			description: description,
			category: subcategory,
		});
		setCost('');
		setDescription('');
	};

	const onCategoryChanged = (newCategoryName: string) => {
		const newCategory = categories.find((category) => category.name === newCategoryName);
		if (!newCategory) {
			return;
		}
		setCategory(newCategory.id);

		const newSubCategories = subCategories.filter((subcategory) => subcategory.categoryId === newCategory.id);
		setAllowedSubCategories(newSubCategories);
		setSubCategory(newSubCategories[0].id);
	};

	const onSubCategoryChanged = (newCategoryName: string) => {
		const newCategory = allowedSubCategories.find((category) => category.name === newCategoryName);
		if (!newCategory) {
			return;
		}
		setSubCategory(newCategory.id);
	};

	return (
		<div>
			<div className="loading-circle" hidden={!addTransactionLoading}>
				<InfinitySpin width="200" color="#4fa94d" />
			</div>
			<form onSubmit={(e) => onSubmit(e)}>
				<Dropdown
					label="Category:"
					className="width100"
					active={category}
					values={allowedCategories}
					onValueChanged={onCategoryChanged}
				></Dropdown>
				<Dropdown
					label="Subcategory:"
					className="width100"
					active={subcategory}
					values={allowedSubCategories}
					onValueChanged={onSubCategoryChanged}
				></Dropdown>

				<div className="form-control">
					<label htmlFor="amount">
						Amount <br />
					</label>
					<input
						type="number"
						id="amount"
						placeholder="Enter amount..."
						value={cost}
						onChange={(e) => setCost(e.target.value)}
					/>
				</div>

				<div className="form-control">
					<label htmlFor="text">Description</label>
					<input
						type="text"
						id="text"
						placeholder="Extra data..."
						value={description}
						onChange={(e) => setDescription(e.target.value)}
					/>
				</div>

				<button className="btn">Add transaction</button>
			</form>
		</div>
	);
};

export default AddTransaction;
