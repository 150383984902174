import axios from 'axios';
import { createContext, useEffect, useReducer } from 'react';
import CategoryReducer from './CategoryReducer';

export type Category = {
	id: number;
	name: string;
};

export type SubCategory = {
	id: number;
	name: string;
	categoryId: Category['id'];
	type: ExpenseType;
};

export enum ExpenseType {
	EXPENSE = 'expense',
	INCOME = 'income',
}

export type CategoryState = {
	categories: Category[];
	subCategories: SubCategory[];
	addCategory: (name: string) => void;
	updateCategory?: (id: number, name: string) => void;
	deleteCategory: (id: number) => void;
	addSubCategory: (name: string, categoryId: number, type: ExpenseType) => void;
	deleteSubCategory: (id: number) => void;
};

const initialState: CategoryState = {
	categories: [],
	subCategories: [],
	addCategory: () => {},
	addSubCategory: () => {},
	deleteSubCategory: () => {},
	deleteCategory: () => {},
};

export enum CATEGORY_ACTIONS {
	GET_CATEGORIES = 'GET_CATEGORIES',
	GET_SUB_CATEGORIES = 'GET_SUB_CATEGORIES',
	ADD_CATEGORY = 'ADD_CATEGORY',
	UPDATE_CATEGORY = 'UPDATE_CATEGORY',
	DELETE_CATEGORY = 'DELETE_CATEGORY',
	ADD_SUB_CATEGORY = 'ADD_SUB_CATEGORY',
	UPDATE_SUB_CATEGORY = 'UPDATE_SUB_CATEGORY',
	DELETE_SUB_CATEGORY = 'DELETE_SUB_CATEGORY',
}

export const CategoryContext = createContext(initialState);

export const CategoryProvider = ({ children }: any) => {
	const [state, dispatch] = useReducer(CategoryReducer, initialState);

	useEffect(() => {
		async function fetchCategories() {
			const response = await axios.get<Category[]>('/categories/all');
			dispatch({
				type: CATEGORY_ACTIONS.GET_CATEGORIES,
				data: response.data,
			});
			const subcategory_response = await axios.get<
				{ id: number; name: string; category_id: number; transaction_type: ExpenseType }[]
			>('/subcategories/all');
			dispatch({
				type: CATEGORY_ACTIONS.GET_SUB_CATEGORIES,
				data: subcategory_response.data.map((subcategory) => ({
					id: subcategory.id,
					name: subcategory.name,
					categoryId: subcategory.category_id,
					type: subcategory.transaction_type,
				})),
			});
		}
		fetchCategories();
	}, []);

	const addCategory = async (name: string) => {
		const formData = new FormData();
		formData.append('name', name);
		const res = await axios.post<{ message: string; id: number }>('/categories', formData, {});
		dispatch({
			type: CATEGORY_ACTIONS.ADD_CATEGORY,
			data: {
				name: name,
				id: res.data.id,
			},
		});
	};

	const addSubCategory = async (name: string, categoryId: number, type: ExpenseType) => {
		const formData = new FormData();
		formData.append('name', name);
		formData.append('category', String(categoryId));
		formData.append('type', type);
		const res = await axios.post<{ message: string; id: number }>('/subcategories', formData, {});

		dispatch({
			type: CATEGORY_ACTIONS.ADD_SUB_CATEGORY,
			data: {
				name: name,
				id: res.data.id,
				categoryId,
				type,
			},
		});
	};

	const updateCategory = (id: number, name: string) => {
		dispatch({
			type: CATEGORY_ACTIONS.UPDATE_CATEGORY,
			data: {
				id,
				name,
			},
		});
	};

	const deleteCategory = async (id: number) => {
		await axios.delete('/categories/' + id);
		dispatch({
			type: CATEGORY_ACTIONS.DELETE_CATEGORY,
			data: {
				id,
			},
		});
	};

	const deleteSubCategory = async (id: number) => {
		await axios.delete('/subcategories/' + id);
		dispatch({
			type: CATEGORY_ACTIONS.DELETE_SUB_CATEGORY,
			data: {
				id,
			},
		});
	};

	return (
		<CategoryContext.Provider
			value={{
				categories: state.categories,
				subCategories: state.subCategories,
				addCategory,
				addSubCategory,
				updateCategory,
				deleteCategory,
				deleteSubCategory,
			}}
		>
			{children}
		</CategoryContext.Provider>
	);
};
