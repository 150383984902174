import React from 'react';
import CategoriesSettings from '../components/Settings/CategoriesSettings';

const Settings = () => {
	return (
		<div className="container settings-page">
			<h2 className="alignCenterVertically">Settings</h2>

			<CategoriesSettings />
		</div>
	);
};

export default Settings;
