import React, { useContext, useState } from 'react';
import { GlobalContext } from '../context/GlobalState';

import Logo from '../logo.png';

const Login = () => {
	const { login, error } = useContext(GlobalContext);
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');

	const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		if (email === '' || password === '') {
			return;
		}
		login(email, password);
	};
	return (
		<div className="alignCentre">
			<div>{error}</div>
			<img className="logo" src={Logo} alt="Wololo" />
			<label className="width100 logo-text">EXPENSE TRACKER</label>
			<form className="width100" onSubmit={(e) => onSubmit(e)}>
				<div className="form-control">
					<label className="defaultTextColor" htmlFor="text">
						Email
					</label>
					<input
						type="text"
						id="text"
						placeholder="Email..."
						value={email}
						onChange={(e) => setEmail(e.target.value)}
					/>
				</div>

				<div className="form-control">
					<label className="defaultTextColor" htmlFor="password">
						Password
					</label>
					<input
						type="password"
						id="text"
						placeholder="Password..."
						value={password}
						onChange={(e) => setPassword(e.target.value)}
					/>
				</div>
				<button className="btn">Login</button>
			</form>
		</div>
	);
};

export default Login;
