import { useContext, useState } from 'react';
import Header from '../components/Header';
import { GlobalContext } from '../context/GlobalState';
import Graphs from './Graphs';
import Login from './Login';
import Main from './Main';
import Notification from '../components/Notification';
import Settings from './Settings';
import { CategoryProvider } from '../context/category/CategoryState';
import { TransactionProvider } from '../context/TransactionState';
import { InsightsProvider } from '../context/Insights/InsightsState';

export enum Pages {
	Main = 'main',
	Graphs = 'graphs',
	Settings = 'settings',
}

const PageSelector = () => {
	const { loggedIn } = useContext(GlobalContext);
	const [page, setPage] = useState(Pages.Main);

	if (!loggedIn) {
		return <Login></Login>;
	}

	const onPageChange = (newPage: Pages) => {
		if (page === newPage) {
			return;
		}
		setPage(newPage);
	};

	return (
		<div>
			<CategoryProvider>
				<TransactionProvider>
					<Notification></Notification>
					<Header activePage={page} onPageChange={onPageChange} />
					{page === Pages.Main && <Main></Main>}
					{page === Pages.Graphs && (
						<InsightsProvider>
							<Graphs></Graphs>
						</InsightsProvider>
					)}
					{page === Pages.Settings && <Settings></Settings>}
				</TransactionProvider>
			</CategoryProvider>
		</div>
	);
};

export default PageSelector;
