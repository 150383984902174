import React, { useContext, useState } from 'react';
import {
	Category as CategoryType,
	CategoryContext,
	ExpenseType,
	SubCategory as SubCategoryType,
} from '../../../context/category/CategoryState';
import Dropdown from '../../Reusable/Dropdown';

const AddSubCategory = ({
	onAddCategory,
	onCloseAddView,
}: {
	onAddCategory: (name: string, expenseType: ExpenseType) => void;
	onCloseAddView: () => void;
}) => {
	const [name, setName] = useState('');
	const [type, setType] = useState('expense');
	const [typeId, setTypeId] = useState(0);

	const onAdd = () => {
		if (name.length > 0 && (type === ExpenseType.EXPENSE || type === ExpenseType.INCOME)) {
			onAddCategory(name, type);
			setName('');
		}
	};

	const onTypeChange = (newType: string) => {
		setType(newType);
		setTypeId(newType === 'Expense' ? 0 : 1);
	};
	return (
		<div className="add-category">
			<div className="container">
				<h2 className="alignCenterVertically">Add new subcategory</h2>
				<label htmlFor="name">
					Category name <br />
				</label>
				<input
					type="text"
					id="name"
					placeholder="Name..."
					value={name}
					onChange={(e) => setName(e.target.value)}
				/>
				<Dropdown
					active={typeId}
					label="Expense type"
					onValueChanged={onTypeChange}
					values={[
						{ id: '0', name: 'expense' },
						{ id: '1', name: 'income' },
					]}
				></Dropdown>
				<div className="add-category-buttons">
					<button className="btn pointer" onClick={() => onAdd()}>
						Add new
					</button>
					<button className="btn pointer" onClick={() => onCloseAddView()}>
						Cancel
					</button>
				</div>
			</div>
		</div>
	);
};

const SubCategory = ({ subcategory, indx }: { subcategory: SubCategoryType; indx: string }) => {
	const { deleteSubCategory } = useContext(CategoryContext);
	const onDelete = () => {
		deleteSubCategory(subcategory.id);
	};
	return (
		<div className="subcategory">
			<li key={subcategory.id + '.' + indx}>
				<button className="delete-btn" onClick={onDelete}>
					x
				</button>
				{subcategory.name}
			</li>
		</div>
	);
};

const Category = ({ category, subCategories }: { category: CategoryType; subCategories: SubCategoryType[] }) => {
	const { addSubCategory, deleteCategory } = useContext(CategoryContext);
	const [showSubCategory, setShowSubcategory] = useState(false);
	const onAddSubCategory = (name: string, expenseType: ExpenseType) => {
		addSubCategory(name, category.id, expenseType);
		setShowSubcategory(false);
	};

	const onAddCategory = () => {
		setShowSubcategory(!showSubCategory);
	};

	const onDeleteCategory = () => {
		deleteCategory(category.id);
	};

	const onCloseAddView = () => {
		setShowSubcategory(false);
	};

	return (
		<>
			<li key={category.id}>
				<button className="delete-btn" onClick={() => onDeleteCategory()}>
					x
				</button>
				{category.name}
				<button className={'add-btn'} onClick={() => onAddCategory()}>
					+
				</button>
			</li>
			{showSubCategory ? (
				<AddSubCategory
					key={category.id + 'subs'}
					onCloseAddView={onCloseAddView}
					onAddCategory={onAddSubCategory}
				></AddSubCategory>
			) : (
				''
			)}
			{subCategories.map((subCategory, indx) => (
				<SubCategory indx={category.id + '.' + indx} subcategory={subCategory} />
			))}
		</>
	);
};

export default Category;
