import React, { useContext, useState } from 'react';
import { FcPlus } from 'react-icons/fc';
import { CategoryContext } from '../../context/category/CategoryState';
import Category from './Categories/Category';

const CategoriesSettings = () => {
	const { addCategory, categories, subCategories } = useContext(CategoryContext);
	const [name, setName] = useState('');

	const onAddCategory = () => {
		if (name.length === 0) {
			return;
		}
		addCategory(name);
		setName('');
	};

	return (
		<>
			<h3>Categories</h3>
			<div className="add_category">
				<input
					type="text"
					id="text"
					placeholder="New category..."
					value={name}
					onChange={(e) => setName(e.target.value)}
				/>
				<FcPlus size="40px" className="pointer" onClick={() => onAddCategory()}></FcPlus>
			</div>
			<ul id="list" className="list">
				{categories.map((category) => (
					<Category
						category={category}
						subCategories={subCategories.filter((subcat) => subcat.categoryId === category.id)}
					/>
				))}
			</ul>
		</>
	);
};

export default CategoriesSettings;
