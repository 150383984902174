import React, { useContext, useEffect } from 'react';
import ConstantGraph from '../components/Graphs/ConstantGraph';
import MonthlyStack from '../components/Graphs/MonthlyStack';
import SingleValueGraph from '../components/Graphs/SingleValueGraph';
import { GlobalContext } from '../context/GlobalState';
import { GraphObject, InsightsContext } from '../context/Insights/InsightsState';

function getGraph(graphObject: GraphObject) {
	switch (graphObject.type) {
		case 'constant':
			return <ConstantGraph graph={graphObject.graph}></ConstantGraph>;
		case 'single_value':
			return <SingleValueGraph graph={graphObject.graph} name={graphObject.name}></SingleValueGraph>;
	}
}

const Graphs = () => {
	const { graphs } = useContext(InsightsContext);
	const { insights, getInsights } = useContext(GlobalContext);
	useEffect(() => {
		const fromDate = new Date();
		fromDate.setMonth(fromDate.getMonth() - 3);
		const toDate = new Date();
		toDate.setMonth(toDate.getMonth() + 1);
		getInsights(fromDate, toDate);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className="graph">
			{graphs.map((graph) => getGraph(graph))}
			{insights.length > 0 && (
				<>
					<div className="width100 alignCenterVertically">Expenses</div>
					<MonthlyStack insights={insights}></MonthlyStack>
				</>
			)}
		</div>
	);
};

export default Graphs;
